<template>
  <div>
    <div
      v-for="(collection, index) in collectionsList"
      :key="collection.id"
      :data-id="collection.id"
    >
<!--      <div-->
<!--        v-if="index % 2 === 0"-->
<!--        class="banner flex"-->
<!--      >-->
<!--        Рекламный блок-->
<!--      </div>-->
      <CollectionItem
        :collection="collection"
        :have-genres="false"
      />
    </div>
  </div>
</template>

<script setup>
import CollectionItem from '@/components/ui/Collections/CollectionItem.vue';

const collectionStore = useCollectionsListStore();
const { collectionsList } = storeToRefs(collectionStore);

await collectionStore.fetchCollections();
</script>
